<template>
	<div class="amap-page-container">
		<el-amap :plugin="plugin" :amap-manager="amapManager" :dragEnable="false" :scrollWheel="false" :zoom="zoom" :center="center" vid="amapDemo" ref="reds"
			style="width:100%;height:40vh" :events="events"></el-amap>
	</div>
</template>

<script>
	import {
		AMapManager
	} from "vue-amap";
	import gisGreen from '../assets/img/0d3264da8.png';
	let amapManager = new AMapManager();
	var map = amapManager.getMap();
	export default {
		props: ['type_cv', 'ad_yui'],
		data() {
			let _obj = this;
			return {
				amapManager,
				center: [108.892252, 34.198946],
				plugin: ['AMap.Scale', {
					pName: 'AMap.ToolBar',
					direction: false
				}],
				zoom: 16,
				events: {
					init(o) {
						_obj.createMap();
					}
				}
			};
		},
		created() {
			// 配置
		},
		mounted() {},
		methods: {
			createMap() {
				let o = amapManager.getMap();
				// o.on('click',function(e){
				// 	o.clearMap()
				// 	console.log(e)
				// 	var icon = new AMap.Icon({
				// 		// 图标尺寸
				// 		size: new AMap.Size(40, 46),
				// 	});
				// 	var marker = new AMap.Marker({
				// 		icon: icon,
				// 		position: [e.lnglat.lng, e.lnglat.lat],
				// 		offset: new AMap.Pixel(-10, -46),
				// 		title: 1,
				// 		text: 1,
				// 	});
				// 	marker.setMap(o);
				// })
				var path = [];
				var that = this;
				var geocoder = new AMap.Geocoder({});
				geocoder.getLocation(that.ad_yui, function(status, result) {
					if (status === 'complete' && result.geocodes.length) {
						var lnglat = result.geocodes[0].location
						that.center = [108.885172,34.19452]
						var marker = new AMap.Marker({
							icon: icon,
							position: [lnglat.lng, lnglat.lat],
							offset: new AMap.Pixel(-30, -40),
							title: 1,
							text: 1,
							label: {
								content: labelContent,
								offset: labelOffset
							}
						});
						marker.setMap(o);
					} else {
						log.error('根据地址查询位置失败');
					}
				});
				var icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(40, 46),
					image:gisGreen,
					// 图标所用图片大小
					imageSize: new AMap.Size(40, 46)
				});
				var labelContent = "<div class='address'><p>西安伟童教育科技有限公司</p><p>陕西省西安市高新区锦业一路1号绿地领海大厦B座808室</p></div>";
				var labelOffset = new AMap.Pixel(-125, -100);
			}
		}
	};
</script>
<style lang="less">
	.amap-marker-label {
		border: 0px;
		padding: 0;
		font-size: 0;
		border-radius: 15px;
	}
	.address{
		border-radius: 15px;
		background-color: #fff;
		padding: 20px;
		box-sizing: border-box;
		box-shadow: 11px 13px 12px 6px #bdbdbd;
		>p{
			font-size: 16px;
			line-height: none;
			margin-bottom: 20px;
		}
		>p:last-child{
			margin-bottom: 0;
		}
	}
	@media screen and (max-width:900px){
		.amap-marker-label {
			
		}
		.address{
			width: 80vw;
			>p{
				font-size: 14px;
				margin-bottom: 10px;
				white-space: break-spaces;
			}
			>p:last-child{
			}
		}
	}
</style>
